import React from 'react';
import Header from '../components/header'
import { Grid, makeStyles } from '@material-ui/core';
import styled from 'styled-components'
import { LinedHeader } from '../components/styledComponents';
import Footer from '../components/footer';
import ThemeCollection from '../components/ThemeCollection';

const useStyles = makeStyles((theme) => ({
  custom: {
    padding: '0 60px',
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  }
}));

const GridContainer = styled(Grid)`
  border: 2px solid #BFADDC;
  margin: 0 0 10px 0;
`
const GridHeader = styled(Grid)`
  background: #F1BA43;
  border-bottom: 2px solid #BFADDC;
`

const Custom = () => {
  const classes = useStyles();

  return (
    <div>
      <Header title="Cakes" />
      <Grid container>
        {/* CAKES */}
        <Grid className={classes.custom} item xs={12}>
          <LinedHeader>Cakes</LinedHeader>
          <Grid >

            <ThemeCollection
              theme_name="Valentine's Day Cakes"
              type="cakes"
              items={[
                "vday5",
                "vday3",
                "anniversary1",
                "vday2",
                "vday1",
                "vday4",
              ]}
            />

            <ThemeCollection
              theme_name="Birthday Cakes"
              type="cakes"
              items={[
                "birthday26",
                "birthday1",
                "birthday3",
                "birthday6",
                "birthday7",
                "birthday4",
                "birthday21",
                "birthday24",
                "birthday5",
                "birthday2",
                "birthday9",
                "birthday16",
                "birthday10",
                "birthday15",
                "birthday8",
                "birthday12",
                "birthday13",
                "birthday22",
                "birthday14",
                "birthday17",
                "birthday18",
                "birthday19",
                "birthday27",
                "birthday28",
              ]}
            />

            <ThemeCollection
              theme_name="Baby Shower Cakes"
              type="cakes"
              items={[
                "babyshower1",
                "babyshower2",
                "babyshower3",
                "babyshower5",
                "babyshower4",
              ]}
            />

            <ThemeCollection
              theme_name="Custom Cakes"
              type="cakes"
              items={[
                "custom24",
                "custom3",
                "custom14",
                "custom7",
                "custom12",
                "custom30",
                "custom28",
                "custom22",
                "custom1",
                "custom4",
                "custom5",
                "custom8",
                "custom10",
                "custom13",
                "custom16",
                "custom17",
                "custom18",
                "custom19",
                "custom20",
                "custom9",
                "custom21",
                "custom6",
                "custom23",
                "custom26",
                "custom29",
              ]}
            />

            <ThemeCollection
              theme_name="Other Cakes"
              type="cakes"
              items={[
                "wedding1",
                "wedding2",
                "graduation1",
                "christmas1",
              ]}
            />

            <ThemeCollection
              theme_name="Cakes"
              type="cakes"
              items={[
                "tiramisu1",
                "blackcake2",
                "tiramisu2",
                "flan2",
                "birthday11",
                "flan1",
                "tiramisu3",
                "sponge1",
                "flan3",
                "sponge2",
                "sponge5",
                "sponge3",
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Custom;
